import * as React from 'react';
import { observer } from "mobx-react-lite";
import Button from '@mui/material/Button';
  
  function ClearAllButton({ store }) {
    //console.log(store)
    
    //Reset Input values if CLEAR ALL button is clicked
    const handleClearButtonClick = (e) => {
        store.clearState()
    };

    //If application is currently uploading files, all components are set to disabled
    let isDisabled = store.PostInsertionStatus["loading"]
    
    return (
        
          <Button name = "ClearButton" variant="outlined" color="error" onClick={handleClearButtonClick} disabled={isDisabled}>
              Clear All
          </Button>
      );
  
  }

  export default observer(ClearAllButton);
  