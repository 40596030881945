import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { observer } from "mobx-react-lite";
import DOEHeader from './DOEHeader';
import DOERow from './DOERow';
import DOESwitch from './DOESwitch';


function DOEFrom({ store }) {
  
  return (
    
    <Box sx={{ flexGrow: 1,
        width: 1600
    }}>
      
      <Grid container spacing={1} columns={36} >
        <Grid container item spacing={3} align="center" alignItems="center">
          <DOEHeader type = "ButtonActive" state = {store}/>
        </Grid>
        <Grid container item spacing={3}>
          <DOERow type = "Robot Serial" state = {store}/>
        </Grid>
        <Grid container item spacing={3}>
          <DOERow type = "Battery Serial" state = {store}/>
        </Grid>
        <Grid container item spacing={3}>
          <DOERow type = "Dock Serial" state = {store}/>
        </Grid>
         
        <Grid container item spacing={3}>
          <DOERow type = "Robot Firmware" state = {store}/>
        </Grid>
        <Grid container item spacing={3}>
            <DOESwitch type = "Robot Firmware" state = {store}/>
        </Grid>
        
        <Grid container item spacing={3}>
          <DOERow type = "Dock Firmware" state = {store}/>
        </Grid>
        <Grid container item spacing={3}>
          <DOESwitch type = "Dock Firmware" state = {store}/>
        </Grid>
      </Grid>
    </Box>
  );
}



export default observer(DOEFrom);
