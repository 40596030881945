import * as React from 'react';
import { useHistory } from 'react-router-dom'
import { observer } from "mobx-react-lite";
import '../App.css';
import Uploader from './Dropzone/Uploader';
import DOEForm from './DOEForm/DOEForm';

import ActionButtons from './ActionButtons/ActionButtons';
import DropzoneButton from './Dropzone/DropzoneButton';

const axios = require('axios').default;

async function DbWakeupApi(store, history) {
  const response = await axios.get(
      store.Config["dbWakeup_API"], {timeout: 240000}
    ).then(
      function (response) {
        if (response?.data?.success === true) {
        store.updatePostInsertionStatus("loading", false)
        }
      }
    ).catch(
      function (response) {
        console.log("Could Not Find Database!");
        console.log(response);
        history.push('/login?dbwakeupfailed');
      }
    ).finally(
      function(response) {
        console.log("FINALLY");
        console.log(response)
      }
    )
  console.log(response)
  // if (response?.data?.success === true) {
  //   store.updatePostInsertionStatus("loading", false)
  // } else {
  //   console.log("Could Not Find Database!")
  //   history.push('/login')
  // }
}

function Home({ store }) {
  const history = useHistory();
  store.updatePostInsertionStatus("loading", true)
  console.log(store)
  DbWakeupApi(store, history);
  let stringifiedName = localStorage.getItem('okta-token-storage');
  let nameObject = JSON.parse(stringifiedName);
  nameObject= nameObject['idToken']['claims']['name']
  store.updateName(nameObject)
   
return (
  <>
  <div className = "container">
    <div className="flex-parent-element">
      <div className="flex-child-element">
        <div className="dropzone-container">
          <div className='dropzone-button'>
            <DropzoneButton store = {store} buttonType = 'Discharge'/>
          </div>
          <Uploader store = {store} fileType = "Discharge"/>
        </div>
      </div>
      <div className="flex-child-element">
        <div className='dropzone-button'>
          <DropzoneButton store = {store} buttonType = 'Charging'/>
        </div>
        <Uploader store = {store} fileType = "Charging"/>
      </div>
      <div className="flex-child-element">
        <div className='dropzone-button'>
          <DropzoneButton store = {store} buttonType = 'NSB'/>
        </div>
        <Uploader store = {store} fileType = "NSB"/>
      </div>
      <div className="flex-child-element">
        <div className='dropzone-button'>
          <DropzoneButton store= {store} buttonType = 'Dockonly' />
        </div>
        <Uploader store= {store} fileType = "Dockonly" />
      </div>
    </div> 
  </div>
  <div className="form-container">
      <DOEForm store={store}/>
  </div>
  <div className="action-button-container">
    <ActionButtons store={store}/>
  </div>  
</>
    )
}

export default observer(Home);