import * as React from 'react';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

let theme = createTheme();
theme = responsiveFontSizes(theme);

const axios = require('axios').default;
//let DOE_API_ENDPOINT = ' https://mc05ltq3vi.execute-api.us-east-1.amazonaws.com/default/doe_pipeline_connector'


function initateFileUpload(store)
{
  store.state.updatePostInsertionStatus("loading", true)
  store.state.updatePostInsertionStatus("dropzoneDisabled", true)
  store.state.setDroppedFilesToUpload()
}

async function triggerDOELambda(store)
{

  // console.log(store.state.stateForUpload)
  
  let stateToUpload = store.state.stateForUpload
  store.state.clearState()
  const response = await axios.get(store.state.Config["doePipelineConnector_API"] , {params: { stateToUpload }});
  // console.log("Initated Pipeline Connector")
  store.state.setFilesToBeDeleted()


  store.state.setStateToAfterLambdaInvoke(response["data"])
  // store.state.updatePostInsertionStatus('response', response["data"])
  // store.state.updatePostInsertionStatus("loading", false)
  // store.state.updatePostInsertionStatus("snackBarOpen", true)
  // store.state.updatePostInsertionStatus("dropzoneDisabled", false)
  
  
  //console.log(response)

}


function InsertButton(store) {
  

  //Pop Up Icon Handlder
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    store.state.updatePostInsertionStatus("snackBarOpen", false)
  };
  
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  //Check to see if dropped files have been uploaded to S3
  if (store.state.fileUploadComplete)
  {
      triggerDOELambda(store)
  }

    let isDisabled = !store.state.canSubmit; 
    let isLoading = store.state.PostInsertionStatusState["loading"]
    let isSnackBarOpen = store.state.PostInsertionStatusState["snackBarOpen"]
    let vertical = "bottom"
    let horizontal = "right"
    let message = store.state.PostInsertionStatusState["response"]
    
    return (

        <>
        <LoadingButton
          style = {{minWidth: '300px', minHeight: '50px'}}
          onClick={() => initateFileUpload(store)}
          disabled = {isDisabled}
          loading={isLoading}
          variant="contained">
          Insert Files into Database
      </LoadingButton>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={action}
    />
    </>
    );
  };

  export default observer(InsertButton);
  
