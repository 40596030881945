import * as React from 'react';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react-lite';

function DropzoneButton(parameters) {

  let store = parameters.store

  const handleDropzoneButtonClick = (e) => {
    let Dropzone = e.target.name
    let updatedButtonState = !store.Dropzones[Dropzone]["active"]

    store.updateDropzoneActiveState(Dropzone, updatedButtonState)

    
    if (!updatedButtonState)
    {
      // console.log("dropzone diabled set")
      store.updateDroppedFilesUploadStatus(Dropzone, "DropzoneDisabled")
    }
    else
    {
      store.updateDroppedFilesUploadStatus(Dropzone, "")
    }

  };

  let isDropzoneActive = store.Dropzones[parameters.buttonType]["active"]

  //Alter Station button to appear enabled or disabled
  if (isDropzoneActive)
    isDropzoneActive = "contained"
  else
    isDropzoneActive = "outlined"

  //If application is currently uploading files, all components are set to disabled
  let isDisabled = store.PostInsertionStatus["loading"]

  return (
    <Button
      name={parameters.buttonType}
      variant={isDropzoneActive}
      disabled={isDisabled}
      onClick={handleDropzoneButtonClick}
      >
      {parameters.buttonType}
    </Button>
  );
};

export default observer(DropzoneButton);

