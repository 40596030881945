import * as React from 'react';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { observer } from "mobx-react-lite";

function DOESwitch(paramaters) {
    let store = paramaters.state

    //Handle Switch Activation/Deactivation
    const handleSwitchChange = (e) => {
        // console.log("Inside switch change")

        //rowType is either Robot Firmware or Dock Firmware
        let rowType = e.target.name.replace(" ", "")

        let updatedButtonState = !store.Switches[rowType]
        store.updateSwitchState(rowType, updatedButtonState)

        //Find first active station
        let firstActiveStation = null
        for (let stationNumber in store.SerialNumbers) {
            if (store.SerialNumbers[stationNumber]["isButtonActive"]) {
                firstActiveStation = stationNumber
                break;
            }
            
        }

        let copiedValue = store.SerialNumbers[firstActiveStation][rowType]

        //Update text value state for each station which is active
        for (let stationNumber in store.SerialNumbers) {
            if (updatedButtonState) {
                if (firstActiveStation != null && stationNumber != firstActiveStation && store.SerialNumbers[stationNumber]["isButtonActive"]) {
                    store.updateState(rowType, stationNumber, copiedValue)
                    // console.log(store.SerialNumbers[stationNumber][rowType])
                }
            }
        }
    };

    //If application is currently uploading files, all components are set to disabled
    let isDisabled = store.PostInsertionStatus["loading"]

    return (
        <Grid item sm={4}>
            <FormControlLabel name={paramaters.type} control={<Switch />} label="Apply All" labelPlacement='start' onChange={handleSwitchChange} disabled={isDisabled} />
        </Grid>
    );

}

export default observer(DOESwitch);