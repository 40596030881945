import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { observer } from "mobx-react-lite";
import InsertButton from './InsertButton';
import ClearAllButton from './ClearAllButton';

  
  function ActionButtons({ store }) {
    //console.log('Rerender Action Buttons')
    
    return (
        <Box sx={{ flexGrow: 1 , width: 1600}} >
          <Grid container spacing={1}>
            <Grid container item xs="auto" align="center" alignItems="center">
              <ClearAllButton store = {store}/>
            </Grid>
            <Grid item xs={10} align="center">
              <InsertButton state = {store}/>
            </Grid>
          </Grid>
        </Box>
      );
  }

  export default observer(ActionButtons);
  
