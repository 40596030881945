import React from 'react'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { observer } from 'mobx-react-lite';


function Uploader(parameters) {

  const axios = require('axios').default;
  
  const dropBoxDisplayString = "Enter your " + parameters.fileType + " file"
  let fileType = parameters.fileType
  let store = parameters.store
  let FILE_NAME;
  const API_ENDPOINT = store.Config["getPresignedFileUrl_API"] //'https://pvshiqmfm9.execute-api.us-east-1.amazonaws.com/default/getPresignedFileUrl'


  const handleChangeStatus = ({ meta, remove, restart }, status) => {
    // console.log("In handle change status")
    // console.log("status, meta");
    // console.log(status, meta);

    FILE_NAME = meta['name']

    //If file is deleted from dropzone
    if (status === "removed") {
      store.updateFileNameState(fileType, "")
      store.updateFileStatusState(fileType, "")
      store.updateDropzoneDroppedState(fileType, false)
    }
    //Otherwise update state to correspond with dropped file
    else {
      store.updateFileStatusState(fileType, status)
      store.updateDropzoneDroppedState(fileType, true)
      store.updateDropzoneFunctions(fileType, "removeFunction", remove)
      store.updateDropzoneFunctions(fileType, "uploadFunction", restart)
    }
  }


  const uploadParams = async ({ meta: { name } }) => {

    const getPresignedUploadParams = await axios.get(API_ENDPOINT, { params: { name: FILE_NAME } });

    console.log(getPresignedUploadParams)
    const fields = getPresignedUploadParams["data"]["fields"]
    const uploadUrl = getPresignedUploadParams["data"]['url']

    // console.log("Fields:")
    // console.log(fields)
    // console.log("UploadURL: " + uploadUrl)
    // console.log("FileType: " + fileType)

    store.updateFileNameState(fileType, FILE_NAME)

    return { fields, url: uploadUrl }
  }

  //If file is ready to be uploaded, initate upload
  if (store.Dropzones[parameters.fileType]["uploadStatus"] == "waitingToUpload") {
    store.updateDroppedFilesUploadStatus(parameters.fileType, 'initated')

    let uploadFunction = store.Dropzones[parameters.fileType]["uploadFunction"]
    uploadFunction()
  }
  //If file upload is complete, delete file from dropzone
  else if (store.Dropzones[parameters.fileType]["uploadStatus"] === "ReadyToDeleteFiles") {
    // console.log("Inside single")
    store.updatePostInsertionStatus("dropzoneDisabled", false)
    store.updateDroppedFilesUploadStatus(parameters.fileType, "")

    let removeFunction = store.Dropzones[parameters.fileType]["removeFunction"]
    removeFunction()
  }
  else if (store.Dropzones[parameters.fileType]["fileDropped"] && store.Dropzones[parameters.fileType]["uploadStatus"] === "DropzoneDisabled")
  {
    // console.log("Inside double")
    store.updateDroppedFilesUploadStatus(parameters.fileType, "")
    let removeFunction = store.Dropzones[parameters.fileType]["removeFunction"]
    removeFunction()
  }

  //If application is currently uploading files, all components are set to disabled
  let dropzoneDisabled = store.PostInsertionStatusState["loading"] || !store.Dropzones[parameters.fileType]["active"]

  return (
    <Dropzone
      disabled={dropzoneDisabled}
      getUploadParams={uploadParams}
      onChangeStatus={handleChangeStatus}
      autoUpload={false}
      canCancel={false}
      canRestart={false}
      inputContent={dropBoxDisplayString}
      maxFiles={1}
      styles={{
        dropzone: { width: 400, height: 200 }
      }} />
  )
}

<Uploader />

export default observer(Uploader)