import { Route, useHistory } from "react-router-dom"
import LandingPage from "./component/LandingPage"
import Home from "./component/Home"
import DOEStore from "./DOEStore";
import Header from "./component/header";
import Login from './component/Custom Okta Login/LoginWidget'
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react'
import { OktaAuth } from '@okta/okta-auth-js'
import { config } from './component/Custom Okta Login/oktaConfig'

function App() {

async function getConfig(store)
{  
  
  const jsonData= require('./config.json'); 
  
  let API_DOE_trigger = jsonData["APIDOETriggerEndpoint"]
  let API_get_preassigned_url = jsonData["APIGetPreassignedURLEndpoint"]
  let API_db_wakeup = jsonData["APIDBWakeupEndpoint"]
  // console.log(API_get_preassigned_url)
  // console.log(API_DOE_trigger)

  // console.log("in front end")
  // console.log("Updated")
  
  store.updateConfig("doePipelineConnector_API", API_DOE_trigger)
  store.updateConfig("getPresignedFileUrl_API", API_get_preassigned_url)
  store.updateConfig("dbWakeup_API", API_db_wakeup)

}


  const history = useHistory()
  const oktaAuth = new OktaAuth(config)

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.push('/protected')
  }

  const customAuthHandler = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    history.push('/login')

  }

  const onAuthResume = async () => {
    history.push('/login')
  }


  const store = new DOEStore();
  getConfig(store)

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={customAuthHandler}>
      <Header/>
      <Route path="/" exact={true} component={LandingPage} />
      <Route path="/login" render={() => <Login />} />
      <Route path="/login/callback" render={props => <LoginCallback {...props} onAuthResume={onAuthResume} />}/>
      <SecureRoute path="/protected" exact={true} render={() =>  <Home store = {store}/> } />
    </Security>  
  )
}


export default App
